var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"id","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista rekomendacji")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink mb-2 mr-2",attrs:{"dark":"","label":"Wyszukaj...","outlined":"","dense":"","prepend-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1)]},proxy:true},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toFixedDecimalPlaces")(item.price,2))+" PLN ")]}},{key:"item.percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toFixedDecimalPlaces")(item.percent,2))+" % ")]}},{key:"item.sent",fn:function(ref){
var item = ref.item;
return [(item.sent)?_c('v-icon',{attrs:{"large":"","color":"green darken-2"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"large":"","color":"red darken-2"}},[_vm._v(" mdi-close ")])]}},{key:"item.decision_deadline",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(item.decision_deadline))+" ")]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }