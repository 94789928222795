import { Client } from "@/models/client/Client";
import { IdNameModelType } from "@/models/CustomTypes";
import moment from "moment";

export class RecommendationListModel {
  id: number | undefined | null;
  price: number | undefined | null;
  percent: number | undefined | null;
  decision_deadline: Date | number | undefined | null;
  sent: boolean | undefined | null;
  client: Client | undefined | null;
  financial_instrument: IdNameModelType | undefined | null;

  constructor(
    id?: number | undefined,
    value?: number | undefined,
    percent?: number | undefined,
    decision_deadline?: Date | undefined,
    sent?: boolean | undefined,
    client?: Client | undefined,
    financial_instrument?: IdNameModelType | undefined
  ) {
    this.id = id;
    this.price = value;
    this.percent = percent;
    this.decision_deadline = decision_deadline;
    this.sent = sent;
    this.client = client;
    this.financial_instrument = financial_instrument;
  }

  deserialize(object: any): RecommendationListModel {
    this.id = object.id as number;
    this.price = Number(object.price);
    this.percent = Number(object.percent);
    this.decision_deadline = object.decision_deadline
      ? moment(object.decision_deadline).unix()
      : null;
    this.sent = object.sent as boolean;
    this.client = Object.assign(new Client(), object.client) as Client;
    this.financial_instrument = object.financial_instrument as IdNameModelType;
    return this;
  }
}
