export class Client {

  id: number | undefined;
  company: string | undefined;

  constructor(id?: number | undefined, company?: string | undefined) {
    this.id = id;
    this.company = company;
  }

}
