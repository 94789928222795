



















































import Vue from "vue";
import Component from "vue-class-component";
import ClientAutocomplete from "@/components/form-controls/ClientAutocomplete.vue";
import { RecommendationApi } from "@/api/RecommendationApi";
import { RecommendationListModel } from "@/context/recommendations/models/recommendations/RecommendationListModel";

@Component({
  components: { ClientAutocomplete }
})
export default class RecommendationsListPage extends Vue {
  headers: Array<Record<string, unknown>> = [
    {
      text: "#",
      value: "id",
      align: "center"
    },
    {
      text: "Klient",
      value: "client.company",
      align: "center"
    },
    {
      text: "Termin decyzji",
      value: "decision_deadline",
      align: "center"
    },
    {
      text: "Wysłane",
      value: "sent",
      align: "start"
    }
  ];
  items: RecommendationListModel[] = [];
  search: string = "";

  mounted() {
    this.loadItems();
  }

  async loadItems() {
    const response = await RecommendationApi.list("search=" + this.search);
    const items = response.data;
    for (let i = 0; i < items.length; i++) {
      items[i] = new RecommendationListModel().deserialize(items[i]);
    }
    this.items = items;
  }
}
